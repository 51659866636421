import React from "react";
import { Box, Divider, Tooltip } from "@mui/material";
import "./dashboard.css";
import Eric from "../../assets/images/Eric-Palmer_450_Web.jpg";
import Cayse from "../../assets/images/Cayse-Mersch_450_Web.jpg";
import Kelley from "../../assets/images/Kelley_Sorensen_450x450.jpg";
import toomey from "../../assets/images/toomey.jpg";
import carrie from "../../assets/images/Carrie_Trammell.png";
import "../dashboard/rightsection.css";
import { color } from "@mui/system";
import AppStore from "../../assets/images/App_Store.png";
import SendUsCase from "../../assets/images/Send us case.png";
import { useNavigate } from "react-router-dom";
import InformationIcon from "../../assets/images/information.png";


const RightSection = () => {
  const navigate = useNavigate()
  const showScript = () => {
    const script = document.createElement("script");

    script.src = "https://mappgps.com/googleChat-connector-global2/abcd.js";
    script.async = true;

    console.log("Printing script in console show script", script);

    document.body.appendChild(script);
  };
  return (
    <>
      <div className="right_section right_section_traning_app right_section_inflation_app">
        <Box className="header right-side-section-header">
          <div className="team right-side-section-team">Apps</div>
          {/* <a
            href="mailto: carrie@premiumalliance.com?subject=Schedule a One-on-One!&body=I would like to meet with the Premium Alliance Team! Here are my available times:"
            target="_blank"
          >
            <button className="btn_your_team">Schedule</button>
          </a> */}
        </Box>
        {/* <Divider /> */}
        <div className="right-side-section-container">
          <a
            onClick={() => {
              navigate("/send-us-case");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="card_app">
              <div className="card_app_header">
                <h4> Send Us A Case </h4>
                <Tooltip
                  title={`Have a case you think may qualify as premium finance?  Submit the details to our team and we'll evaluate the information and work with you to 
                                    find the best solution for the client.`}
                  arrow={true}
                >
                  <img src={InformationIcon} />
                </Tooltip>
              </div>
              <div className="card_app_description">
                <img
                  src={SendUsCase}
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
          </a>
        </div>

        {/* <Box className="name_of_member">
          <div className="img">
            <img src={Eric}></img>
          </div>
          <div className="data">
            <div className="name">Eric Palmer</div>
            <div className="occupation">
              Education, Premium Finance Design, and Presentations
            </div>
          </div>
        </Box> */}
        {/* <Box className="name_of_member">
          <div className="img">
            <img src={toomey}></img>
          </div>
          <div className="data">
            <div className="name">Marc Toomey</div>
            <div className="occupation">
              Premium Finance Design, and Presentations
            </div>
          </div>
        </Box> */}
        {/* <Box className="name_of_member">
          <div className="img">
            <img src={carrie}></img>
          </div>
          <div className="data">
            <div className="name">Carrie Trammell</div>
            <div className="occupation">Client Relationship Manager</div>
          </div>
        </Box> */}
        {/* <Box className="name_of_member">
          <div className="img">
            <img src={Cayse}></img>
          </div>
          <div className="data">
            <div className="name">Cayse Mersch</div>
            <div className="occupation">Case Design & Underwriting</div>
          </div>
        </Box> */}

        {/* <Box className="name_of_member">
          <div className="img">
            <img src={Kelley}></img>
          </div>
          <div className="data">
            <div className="name">Kelley Sorensen</div>
            <div className="occupation">Policy & Loan Management</div>
          </div>
        </Box>
         */}
      </div>
    </>
  );
};

export default RightSection;
